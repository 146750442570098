<template>
  <div class="dataexplore-container">
    <div class="data-bar">
      <span class="control-left">
        <span>{{ $t(`navbar.ConsumerData`) }}</span>
      </span>
      <div>
        <span>
          <el-form
            :inline="true"  
            :model="filterOptions"
            label-position="right"
            label-width="60px"
            class="demo-form-inline"
          >
            <el-form-item 
              :label="$t(`field.version`)"
              :label-width="'100'"
            >
              <el-select
                v-model="filterOptions.selectedVersion"
                filterable
                @change="selectVersion"
              >
                <el-option
                  v-for="item in versions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </span>
      </div>
    </div>
    <el-tabs
      v-model="activePanel"
      type="card"
      tab-position="left"
      @tab-click="handleTabChange"
    >
      <el-tab-pane
        v-for="tab in tabs"
        :key="tab.name"
        :name="tab.name"
        :label="$t(`general.${tab.name}`)"
      >     
        <el-dialog
          :title="$t(`DataExplore.downloadDialogTitle`)"
          :visible.sync="dialogVisible"
          :modal="false"
          width="60%"
          :before-close="handleClose"
        >
          <div class="dialog-selected-block">
            <div>
              {{ $t(`DataExplore.selectedDatatype`) }}
              <span class="explore-datatype">
                {{ $t(`general.${activePanel}`) }}
                -{{ $t(`mainType.${selectedType}`) }}
                <span v-if="selectedSubType">
                  -{{ $t(`subType.${selectedSubType}`) }}
                </span>
              </span>
              ，{{ $t(`DataExplore.apiKey`) }}：
            </div>
            <div class="text-section">
              <span>
                {{ $t(`general.Datatype`) }}
                main_type:
                <span class="text-color">
                  "{{ activePanel }}"
                </span>
              </span>
            </div>
            <div class="text-section">
              <span>
                {{ $t(`field.type`) }}
                type:
                <span class="text-color">
                  "{{ selectedType }}"
                </span>
              </span>
            </div>
            <div v-if="selectedSubType" class="text-section">
              <span>
                {{ $t(`field.subtype`) }}
                subtype:
                <span class="text-color">
                  "{{ selectedSubType }}"
                </span>
              </span>
            </div>
          </div>
          <div>
            API
            <span>[GET] {{ apiLink }} </span>
            <el-button 
              class="btn-copy"
              size="medium" 
              type="text"
              @click="copy"
            >
              <i class="el-icon-document-copy" />
            </el-button>
          </div>
          <div>
            <a 
              class="swagger-text"
              :href="swaggerLink"
              :aria-label="$t('Aria.TargetBlank')+','+$t('Aria.Goto')+'OpenAPI'"
              target="_blank"
            >
              Swagger {{ $t(`DataExplore.interface`) }}
            </a>
          </div>
        </el-dialog>
        <template v-if="dataEmpty">
          <div class="data-empty-container">
            <img :src="explorePic_Empty">
            <div class="data-empty-text">{{ $t(`DataExplore.dataEmpty`) }}</div>
          </div>
        </template>
        <template v-else>
          <el-row class="datatype-row explore-layout">
            <el-col :span="3" class="datatype-th">{{ $t(`field.type`) }}</el-col>
            <el-col :span="21" class="datatype-options">
              <el-radio-group 
                v-model="selectedType"
                fill="#909399"
                @change="selectMainType"
              >
                <el-radio-button 
                  v-for="(item,index) in maintype"
                  :key="index"
                  :label="item"
                >
                  {{ $t(`mainType.${item}`) }}
                </el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row class="datatype-row explore-layout">
            <el-col :span="3" class="datatype-th">{{ $t(`field.subtype`) }}</el-col>
            <el-col :span="21" class="datatype-options">
              <template v-if="noSubtype">
                <div class="nodata">{{ $t(`Spec.noData`) }}</div>
              </template>
              <el-radio-group 
                v-model="selectedSubType"
                fill="#909399"
                @change="selectSubType"
              >
                <el-radio-button 
                  v-for="(item,index) in subtype"
                  :key="index"
                  :label="item"
                >
                  {{ $t(`subType.${item}`) }}
                </el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row class="main-contain explore-layout">
            <el-col :span="13">
              <template v-if="language==='zh-TW'">
                <div class="text-section">
                  本數據集是
                  <span class="explore-datatype">
                    {{ $t(`general.${activePanel}`) }}
                    -{{ $t(`mainType.${selectedType}`) }}
                    <span v-if="selectedSubType">
                      -{{ $t(`subType.${selectedSubType}`) }}
                    </span>
                  </span>
                </div>
                <div class="text-section">
                  收集全台各年齡層、各縣市、不同性別的
                  {{ $t(`mainType.${selectedType}`) }}<span v-if="selectedSubType">-{{ $t(`subType.${selectedSubType}`) }}</span>
                  愛好者的各項統計數據
                </div>
                <div class="text-section">
                  並依據原始資料的不同，提供
                  <span
                    v-for="(item,index) in fields"
                    :key="index"  
                  >
                    {{ $t(`field.${item}`) }},
                  </span>
                  等其他細節資訊
                </div>
              </template>
              <template v-else>
                <div class="text-section">
                  The data originates from the category 
                  <span class="explore-datatype">
                    {{ $t(`general.${activePanel}`) }}
                    -{{ $t(`mainType.${selectedType}`) }}
                    <span v-if="selectedSubType">
                      -{{ $t(`subType.${selectedSubType}`) }}
                    </span>
                  </span>
                </div>
                <div class="text-section">
                  We have gathered comprehensive statistics on
                  {{ $t(`mainType.${selectedType}`) }}<span v-if="selectedSubType">-{{ $t(`subType.${selectedSubType}`) }}</span>
                  enthusiasts spanning all age groups, from various cities and counties, and representing different genders throughout Taiwan.
                </div>
                <div class="text-section">
                  Depending on the variations in the primary data, we provide measurement metrics like
                  <span
                    v-for="(item,index) in fields"
                    :key="index"  
                  >
                    {{ $t(`field.${item}`) }}.
                  </span>
                </div>
              </template>
              <template>
                <div class="text-section">{{ $t(`DataExplore.sdv_count`) }}<span>{{ sdv_count }}</span> </div>
                <!-- <div class="text-section">{{ $t(`DataExplore.download_times`) }}<span>{{ download_times }}</span></div> -->
              </template>
              <el-row class="action-block">
                <el-row class="source-notify"> 
                  <span>
                    {{ $t(`Notify.DownloadSource`) }}
                  </span>
                  <span v-if="showDownloadButtons">
                    {{ $t(`Notify.DownloadLimit`) }}
                  </span>
                </el-row>
                <template v-if="showDownloadButtons">
                  <el-button 
                    v-for="(item,index) in actionButtons"
                    :key="index"
                    :loading="fileLoadings[item.id]"
                    @click="handleDownloadSDVData(item.value, item.id)"
                  >
                    {{ item.name }}
                    <i class="el-icon-download el-icon--right" />
                  </el-button>
                </template>
                <el-button
                  type="text"
                  @click="dialogVisible = true"
                >
                  API
                  <i class="el-icon-info" />
                </el-button>
              </el-row>
            </el-col>
            <el-col class="image-col" :span="11">
              <img 
                v-if="activePanel==='Sport'"  
                :src="explorePic_sport"
                class="image"
              >
              <img
                v-if="activePanel==='PhysicalFitness'" 
                :src="explorePic_phy"
                class="image"
              >
              <img
                v-if="activePanel==='Physiology'" 
                :src="explorePic_Physiology"
                class="image"
              >
            </el-col>
          </el-row>
        </template>
        
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getDatatype, getSDVdataInfo, downloadSDVData } from '@/api/data'
import { downloadFileFromBinary } from '@/utils/downloadFile'
import { SuccessMessage } from '@/Mixins'

const params = () => ({
  main_type: 'Sport',
  type: '', 
  subtype: '',
  version: null
})

export default {
  name: 'DataExplore',
  mixins: [SuccessMessage],
  data () {
    return {
      params: params(),
      tabs: [
        { name: 'Sport' },
        { name: 'PhysicalFitness' },
        { name: 'Physiology' }
      ],
      actionButtons: [
        { name: 'ODS', value: 'ods', id: 1 },
        { name: 'CSV', value: 'csv', id: 2 },
        { name: 'Excel', value: 'xlsx', id: 3 }
      ],
      filterOptions: {
        selectedVersion: null
      },
      explorePic_sport: require('@/assets/images/explore/explore_sport.png'),
      explorePic_phy: require('@/assets/images/explore/explore_phy.png'),
      explorePic_Physiology: require('@/assets/images/explore/explore_health.png'),
      explorePic_Empty: require('@/assets/images/explore/explore_empty.svg'),
      apiLink: 'https://api.data-sports.tw/data/processed',
      swaggerLink: 'https://api.data-sports.tw/swagger-ui/#/Consumer%20%E6%95%B8%E6%93%9A%E9%81%8B%E7%94%A8%E8%80%85/get_data_processed',
      download_times: 0,
      sdv_count: 0,
      allOptions: null,
      activePanel: 'Sport',
      selectedType: '',
      selectedSubType: '',
      noSubtype: false,
      version: [],
      maintype: [],
      subtype: [],
      fields: [],
      paraStr: '',
      downloadType: '',
      dataEmpty: false,
      fileLoadings: {},
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters(['versions']),
    language() {
      return this.$store.getters.language
    },
    versionOptions() {
      return this.versions
    },
    showDownloadButtons() {
      return this.sdv_count > 99
    }
  },
  mounted() {
    this.fetchInfo()
    this.loadData()
  },
  methods: {
    loadData () {
      this.getParaStr()
    },
    async fetchInfo() {
      this.allOptions = (await getDatatype({ sdv_data: true })).data
      this.params.version = this.versionOptions[0]
      this.filterOptions.selectedVersion = this.versionOptions[0]
      this.setMainTypeOptions()
    },
    async fetchData() {
      const res = (await getSDVdataInfo(this.params)).data
      this.download_times = res.download_times
      this.sdv_count = res.sdv_count
      this.fields = res.fields
    },
    selectMainTypebySearch(main_type, type) {
      this.params.main_type = main_type
      this.params.type = type
      this.params.subtype = ''
      this.fetchData()
    },
    selectsubTypebySearch(main_type, type, subtype) {
      this.params.main_type = main_type
      this.params.type = type
      this.params.subtype = subtype
      this.fetchData()
    },
    setMainTypeOptions() {
      this.maintype = Object.keys(this.allOptions[this.activePanel])
      if (this.maintype.length) {
        this.params.type = this.maintype[0]
        this.selectedType = this.maintype[0]
        this.setSubTypeOptions()
        this.fetchData()
      } else {
        this.dataEmpty = true
      }
    },
    setSubTypeOptions() {
      this.subtype = this.allOptions[this.activePanel][this.selectedType]
      if (!this.subtype.length) {
        this.noSubtype = true
      } else {
        this.noSubtype = false
      }
      this.params.subtype = this.subtype[0]
      this.selectedSubType = this.subtype[0]
    },
    getParaStr() {
      const strArr = []
      let paraStr = ''
      const obj = this.params
      for (const p in obj) {
        if (obj[p]) {
          strArr.push(p + '=' + obj[p])
        }
      }
      paraStr = strArr.join('&')
      this.paraStr = paraStr
    },
    handleTabChange() {
      this.dataEmpty = false
      this.params.main_type = this.activePanel
      this.setMainTypeOptions()
    },
    selectMainType() {
      this.params.type = this.selectedType
      this.setSubTypeOptions()
      this.fetchData()
    },
    selectSubType() {
      this.params.subtype = this.selectedSubType
      this.fetchData()
    },
    selectVersion() {
      this.params.version = this.filterOptions.selectedVersion
      this.fetchData()
    },
    fetchDownloadSDVData(dataTypeid) {
      this.$set(this.fileLoadings, dataTypeid, true)
      
      downloadSDVData(this.params)
        .then((res) => {
          const fileName = this.selectedSubType
            ? this.$t(`mainType.${this.selectedType}`) + '-' + this.$t(`subType.${this.selectedSubType}`) 
            : this.$t(`mainType.${this.selectedType}`)
          downloadFileFromBinary(res, `${fileName}.${this.downloadType}`)
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.$set(this.fileLoadings, dataTypeid, false)
        })
    },
    handleDownloadSDVData(actionType, dataTypeid) {
      this.params.file_format = actionType
      this.downloadType = actionType
      this.fetchDownloadSDVData(dataTypeid)
    },
    handleClose() {
      this.dialogVisible = false
    },
    copy() {
      const copyData = this.apiLink

      if (navigator.clipboard) {
        navigator.clipboard.writeText(copyData)
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = copyData
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        return new Promise((res, rej) => {
          document.execCommand('copy') ? res() : rej
          textArea.remove()
        })
      }
      this.showSuccessMessage(this.$t('general.Copy'))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.dataexplore-container {
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  .data-bar {
    display:flex;
    justify-content: space-between;
    .control-left{
      text-align: left;
    }
    .el-form-item{
      margin-bottom: 0px;
    }
  }
  .el-tab-pane{
    border-left: 1px solid $info;
  }
  .dialog-selected-block{
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: $background_blue;
    color: $text_light;
    .text-color{
      color: #7ef7fd;
    }
  }
  .swagger-text{
    color: $link;
  }
  .swagger-text:hover{
    color: $text_light;
    background-color: $link;
  }
  .data-empty-container{
    padding: 15%;
    text-align: center;
    .data-empty-text{
      line-height: 3;
    }
  }
  .explore-layout{
    padding: 20px 50px;
  }
  .datatype-row{
    border-bottom: 1px solid $info;

    .datatype-th{
      padding-top: 10px;
      
    }
    .datatype-options{
      text-align: left;
      .nodata{
        color: $text;
        font-size: 1rem;
        padding-top: 15px;
      }
    }
  }

  .main-contain{
    font-size: 1rem;
    .text-section{
      padding: 5px 0;
    }
    .explore-datatype{
      font-weight: bold;
    }
    .action-block{
      padding-top: 30px;
    }
    .image-col{
      position: relative;
      .image {
        position: absolute;
        width: 80%;
        right: 0;
        top: 70px;
      }
    }
  }
  .source-notify{
    font-size: 0.875rem;
    color:$danger;
  }
  
}
</style>
